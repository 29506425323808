'use strict';

angular.module('enervexSalesappApp').factory('Fan', function ($resource) {
	return $resource('/api/fans/:id/:action', {
		id: '@_id',
		action: '@action'
	},
	{
		update: {
			method: 'PUT'
		},
		import: {
			method: 'POST',
			timeout: 340000,
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined,
				'Accept': "application/json"
			},
			params:{
				action: "import"
			}
		},
		getFEICharts: {
            method: 'POST',
            params: {
            	action: 'fei-charts'
            }
        },
        calculateSingleFEIRun: {
            method: 'POST',
            params: {
            	action: 'calculate-fei'
            }
        },
	});
});
